import moment from "moment";
import {ElNotification} from 'element-plus'

import parsePhoneNumber from 'libphonenumber-js'


export default {


    data() {

        return {
            monthNames: [
                "января",
                "февраля",
                "марта",
                "апреля",
                "мая",
                "июня",
                "июля",
                "августа",
                "сентября",
                "октября",
                "ноября",
                "декабря",
            ],

            monthFirstForm: [
                {name: "Январь"},
                {name: "Февраль"},
                {name: "Март"},
                {name: "Апрель"},
                {name: "Май"},
                {name: "Июнь"},
                {name: "Июль"},
                {name: "Август"},
                {name: "Сентябрь"},
                {name: "Октябрь"},
                {name: "Ноябрь"},
                {name: "Декабрь"},
            ],

            weekDays: [
                "Воскресенье",
                "Понедельник",
                "Вторник",
                "Среда",
                "Четверг",
                "Пятница",
                "Суббота",
            ],

            weekDaysRu: [
                "Понедельник",
                "Вторник",
                "Среда",
                "Четверг",
                "Пятница",
                "Суббота",
                "Воскресенье",
            ],


            weekDaysShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
            weekDaysShortEn: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],

            //
            //     var search = '';
            //     for (var i = 0; i < str.length; i++) {
            //     search += map[str.charAt(i)] || str.charAt(i);
            // }
            // replaceEnToRu: {
            //     'q': 'й',
            //     'w': 'ц',
            //     'e': 'у',
            //     'r': 'к',
            //     't': 'е',
            //     'y': 'н',
            //     'u': 'г',
            //     'i': 'ш',
            //     'o': 'щ',
            //     'p': 'з',
            //     '[': 'х',
            //     ']': 'ъ',
            //     'a': 'ф',
            //     's': 'ы',
            //     'd': 'в',
            //     'f': 'а',
            //     'g': 'п',
            //     'h': 'р',
            //     'j': 'о',
            //     'k': 'л',
            //     'l': 'д',
            //     'z': 'я',
            //     'x': 'ч',
            //     'c': 'с',
            //     'v': 'м',
            //     'b': 'и',
            //     'n': 'т',
            //     'm': 'ь',
            //     'Q': 'Й',
            //     'W': 'Ц',
            //     'E': 'У',
            //     'R': 'К',
            //     'T': 'Е',
            //     'Y': 'Н',
            //     'U': 'Г',
            //     'I': 'Ш',
            //     'O': 'Щ',
            //     'P': 'З',
            //     'A': 'Ф',
            //     'S': 'Ы',
            //     'D': 'В',
            //     'F': 'А',
            //     'G': 'П',
            //     'H': 'Р',
            //     'J': 'О',
            //     'K': 'Л',
            //     'L': 'Д',
            //     ';': 'Ж',
            //     '\'': 'Э',
            //     'Z': '?',
            //     'X': 'ч',
            //     'C': 'С',
            //     'V': 'М',
            //     'B': 'И',
            //     'N': 'Т',
            //     'M': 'Ь',
            //     ',': 'Б',
            //     '.': 'Ю',
            //     'ё': 'е',
            //     'Ё': 'Е',
            // },

        }
    },


    computed: {

        today() {
            const date = new Date(); // текущая дата
            const year = date.getFullYear(); // текущий год
            const month = date.getMonth(); // текущий месяц,
            const day = date.getDate(); // текущий день месяца (число)
            const dayNum = this.getDay(date); //date.getDay() === 0 ? 6 : date.getDay() - 1
            return {
                year,
                month,
                day,
                dayNum,
                time: new Date(year, month, day).getTime() / 1000, // сегодня - время timestamp
                daysInMonth: new Date(year, month + 1, 0).getDate(), // кол месяцев в текущем (сегодня) месяце
            };
        },
    },


    methods: {

        getWeekNumber(date) {


            // первая версия, иногда в начале года в 1ю неделию дает ошибки
            // // первое января для текущей даты
            // let oneJan = new Date(date.getFullYear(), 0, 1);
            // // номер дня от начала года
            // let numberOfDays = Math.floor((date - oneJan) / (24 * 60 * 60 * 1000));

            // // получаем текущий день недели, 6 минус этот день, прибавляем к текущему дня от начала года,
            // // так получим воскресенье
            // return Math.ceil((6 - this.getDay(date) + numberOfDays) / 7);

            // пока наблюдаю
            const currentDate = new Date(date.getTime());

            // Устанавливаем первый день недели на понедельник
            currentDate.setHours(0, 0, 0, 0);

            // Найдем четверг текущей недели (в ISO-нумерации недели начинаются с понедельника)
            currentDate.setDate(currentDate.getDate() + (4 - (currentDate.getDay() || 7)));

            // Получаем начало года
            const yearStart = new Date(currentDate.getFullYear(), 0, 1);

            // Рассчитываем номер недели
            const weekNumber = Math.ceil((((currentDate - yearStart) / 86400000) + 1) / 7);

            return weekNumber;

        },

        // эта функция нужна: т.к. вск идет первым днем, а нужно, чтобы он был последним
        getDay(date) {
            // получить номер дня недели, от 0 (пн) до 6 (вс)
            let day = date.getDay();
            if (day == 0) day = 7; // сделать воскресенье (0) последним днем
            return day - 1;
        },


        compress(file, callback, size = 1080) {


            return new Promise((resolve, reject) => {

                const fileName = file.name;
                const reader = new FileReader();

                reader.readAsDataURL(file);


                reader.onload = event => {
                    const img = new Image();

                    img.src = event.target.result;
                    img.onload = async () => {
                        const elem = document.createElement('canvas');


                        let width = 0;
                        let height = 0;
                        let scaleFactor = 0;

                        if (img.width > img.height) {
                            width = size;
                        }
                        else {
                            height = size;
                        }

                        if (img.width <= size && img.height <= size) {
                            await callback.call(null, file);
                            resolve(true)
                        }
                        else {
                            if (width) {
                                elem.width = width;
                                scaleFactor = width / img.width;
                                height = Math.round(img.height * scaleFactor);
                                elem.height = height;
                            }
                            else {
                                elem.height = height;
                                scaleFactor = height / img.height;
                                width = Math.round(img.width * scaleFactor);
                                elem.width = width;
                            }

                            const ctx = elem.getContext('2d');
                            // img.width и img.height будет содержать оригинальные размеры
                            ctx.drawImage(img, 0, 0, width, height);


                            ctx.canvas.toBlob(async (blob) => {
                                const file = new File([blob], fileName, {
                                    type: 'image/jpeg',
                                    lastModified: Date.now()
                                });

                                if (typeof callback === 'function') {
                                    await callback.call(null, file);
                                    resolve(true)
                                }

                            }, 'image/jpeg', 0.7);
                        }


                    };
                    reader.onerror = error => {
                        console.log(error);
                        reject(false);
                    }
                };


            });


        },


        formatDate(date, time) {
            const d = moment(date).format('D');
            const m = moment(date).format('M');
            const y = moment(date).format('YYYY');
            return moment(`${y}-${m}-${d} ${time}`, "YYYY-MM-DD HH:mm:ss").unix();
        },


        dateFormat(date) {
            switch (typeof date) {
                case 'number':
                    date = date * 1000
                    break;
            }
            const d = new Date(date);
            let day = d.getDate()
            let month = d.getMonth() + 1;
            if (day < 10) day = '0' + day
            if (month < 10) month = '0' + month
            return `${day}.${month}.${d.getFullYear()}`;
        },

        notify(title, message = '', type) {
            ElNotification({
                title,
                message,
                type,
            });
            return false;
        },

        phoneFormat(number) {
            try {
                number = number + '';
                const phoneNumber = parsePhoneNumber(number, 'RU');
                number = phoneNumber.number
                return {
                    phone: phoneNumber.formatNational(),
                    uri: phoneNumber.getURI(),
                    number: number.slice(0, 1) === '+' ? number = number.slice(1) : number,
                }
            }
            catch (e) {
            }

            return {
                phone: '',
                uri: '',
                number: '',
            };

        },


        declOfNum(n, forms) {
            return n % 10 == 1 && n % 100 != 11 ? forms[0] : (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? forms[1] : forms[2]);
        },
        declOfNumHours(num) {
            return this.declOfNum(num, ['час', 'часа', 'часов']);
        },
        declOfNumMinutes(num) {
            return this.declOfNum(num, ['минута', 'минуты', 'минут']);
        },
        declOfNumSeconds(num) {
            return this.declOfNum(num, ['секунда', 'секунды', 'секунд']);
        },
        declOfNumPortfolio(num) {
            return this.declOfNum(num, ['работа', 'работы', 'работ']);
        },
        declOfNumDay(num) {
            return this.declOfNum(num, ['день', 'дня', 'дней']);
        },
        declOfNumMonth(num) {
            return this.declOfNum(num, ['месяц', 'месяца', 'месяцев']);
        },
        declOfNumWeek(num) {
            return this.declOfNum(num, ['неделю', 'недели', 'недель']);
        },
        declOfNumRate(num) {
            return this.declOfNum(num, ['отзыв', 'отзыва', 'отзывов']);
        },


        number_format(number, decimals, dec_point, thousands_sep) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            var out = s.join(dec);
            return out;
        },

        numFormat(n) {
            //return this.number_format(n, (Math.floor(n)===n ? 0 : 2), '.', ' ');
            return this.number_format(n, 0, '.', ' ');
        },

        /**
         * Function to sort alphabetically an array of objects by some specific key.
         *
         * @param {String} property Key of the object to sort.
         */

        // сортировать по возрастанию: People.sort(dynamicSort("Surname"));
        // сортировать по убыванию: People.sort(dynamicSort("-Surname"));


        dynamicSort(property) {
            let sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                if (sortOrder == -1) {
                    return b[property].localeCompare(a[property]);
                }
                else {
                    return a[property].localeCompare(b[property]);
                }
            }


        },

        objIsEmpty(obj) {
            for (const val in obj) {
                return false
            }
            return true
        },

        formatTime(time) {

            let h = 0;
            let m = 0;

            if (typeof time === 'number') {
                const date = new Date(time * 1000)
                h = date.getHours()
                m = date.getMinutes()
            }
            else if (typeof time === 'object' && typeof time.getMonth === 'function') {
                h = time.getHours()
                m = time.getMinutes()
            }
            else {
                h = time.h
                m = time.m
            }


            if (+h < 10) h = "0" + h;
            if (+m < 10) m = "0" + m;
            return `${h}:${m}`;
        },

        getTime(time) {
            const date = new Date(+time * 1000);

            let h = date.getHours();
            let m = date.getMinutes();

            if (h < 10) h = "0" + h;
            if (m < 10) m = "0" + m;

            return h + ":" + m;
        },


    }

};
